/* eslint-disable jsx-a11y/anchor-is-valid */
import './Header.css';

function Header() {
  return (
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid d-flex flex-column">
        <a class="navbar-brand roboto-mono" href="#"><span class="big-text">GEOPROFIL</span></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a class="nav-link active roboto-mono" aria-current="page" href="#">O mnie!</a>
            <a class="nav-link active roboto-mono" href="#">Moje usługi!</a>
            <a class="nav-link active roboto-mono" href="#">Skontaktuj się!</a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
