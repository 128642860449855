/* eslint-disable jsx-a11y/anchor-is-valid */
import './Main.css';
import {useRef} from "react"
import emailjs from '@emailjs/browser';

const Main = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_lfy756g', 'template_8z7lhgz', form.current, 
    'qZ_A3fDoSe_AAAJYx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    };

  return (
    <section role="main" className="text-center container roboto-mono main-content">
      <div className="row">
        <div className="col-md-12 blog-main">
        
          <h3 className="pb-3 mb-4 font-italic blog-post">
            Witaj na stronie Geoprofil!!!
          </h3>
          <hr/>

          <div className="blog-post">
            <h2 className="blog-post-title header">O mnie!</h2>

            <p>Firma prowadzi swoją działalność w ramach branży Geodezja. <br></br> "Geoprofil" Firma Geodezyjna Sebastian Pietryga ma swoją siedzibę w <br></br> miejscowości Tarnowskie Góry w województwie śląskim. <br></br></p>

          </div>
          <hr/>
          <div className="blog-post blog-post-services">
            <h2 className="blog-post-title header">Usługi</h2>

            <p  className="paragraph">Jesteśmy wiodącą firmą geodezyjną, specjalizującą się w świadczeniu<br></br> kompleksowych usług geodezyjnych dla klientów z <br></br> różnorodnych sektorów. Oto kilka z usług, które oferujemy:</p>

              <ul className="list-group bg-body-tertiary">
                <li className="list-group-item bg-body-tertiary extra-margin">
                  Pomiary geodezyjne: Nasz zespół doświadczonych geodetów wykonuje <br></br>różnorodne pomiary terenowe, w tym pomiary granic działek, <br></br> oraz inne pomiary potrzebne do realizacji projektów budowlanych czy inwestycyjnych.
                </li>
                
                <li className="list-group-item bg-body-tertiary extra-margin">
                  Mapy i plany sytuacyjne: Tworzymy precyzyjne mapy i plany sytuacyjne, <br></br>które są niezbędne do celów projektowych, urbanistycznych, planistycznych, jak <br></br>również dla potrzeb inwestorów, deweloperów oraz instytucji publicznych.
                </li>
                
                <li className="list-group-item bg-body-tertiary extra-margin">
                  Geodezyjna obsługa inwestycji: Zapewniamy kompleksową obsługę <br></br>geodezyjną inwestycji budowlanych i infrastrukturalnych na każdym etapie realizacji <br></br>projektu, od pomiarów wstępnych, przez tyczenie i kontrolę wykonawczą, aż <br></br>po aktualizację dokumentacji po zakończeniu prac.
                </li>
              </ul>
                
          </div>

          <hr/>
          <div className="blog-post">
            <h2 className="blog-post-title header">Kontakt</h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <label for="exampleFormControlInput1">Adres Email</label>
                <input name="email" type="email" className="form-control input-form mx-auto" id="exampleFormControlInput1" placeholder="janusz@kowalski.com"/>
              </div>
              <div className="form-group">
                <label for="exampleFormControlTextarea1">Wiadomość</label>
                <textarea name="message" className="form-control input-form mx-auto" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
              <div className="form-group m-4">
                <input type="submit" value="Wyślij" />
              </div>
            </form>
          </div>

        </div>

      </div>

    </section>
  );
}

export default Main;