/* eslint-disable jsx-a11y/anchor-is-valid */
import './Footer.css';

function Footer() {
  return (
    <footer class="text-center text-lg-start bg-body-tertiary text-muted roboto-mono">
      <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-google"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-linkedin"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-github"></i>
          </a>
        </div>
      </section>
    
      <section class="">
        <div class="container text-center text-md-start mt-5">
          <div class="row mt-3">
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4">
                GEOPROFIL
              </h6>
              <p>
                Firma z wieloletnim doświadczeniem na rynku usług geodezji.
              </p>
            </div>
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4">
                Usługi
              </h6>
              <p>
                Mapy do celów projektowych
              </p>
              <p>
                Podziały nieruchomości
              </p>
              <p>
                Pomiary sytuacyjno-wysokościowe
              </p>
              <p>
                I więcej... 
              </p>
            </div>
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4">
                INFORMACJE
              </h6>
              <p>
                NIP: 6452155158
              </p>
              <p>
                REGON: 241214237
              </p>
              <p>
                indywidualna działalność gospodarcza
              </p>
              <p>
                SEBASTIAN PIETRYGA
              </p>
            </div>
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 class="text-uppercase fw-bold mb-4">Kontakt</h6>
              <p>ul. Radzionkowska 47, 42-605 Tarnowskie Góry</p>
              <p>
                geodezja.tarnowskiegory@gmail.com
              </p>
              <p>+48 887 560 070</p>
            </div>
          </div>
        </div>
      </section>
      <div class="text-center p-4">
        Copyright (C) Geoprofil. Wszystkie prawa Zastrzeżone.
      </div>
    </footer>
  );
}


<footer className="mastfoot mt-auto">
<div className="inner roboto-mono">
  <p> </p>
</div>
</footer>

export default Footer;
